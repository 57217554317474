<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:24:16
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.model-action {
  height: 46px; 
  line-height: 36px; 
  width: 300px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(68, 125, 245, 1);
  &:hover {
    cursor: pointer;
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>

<template>
  <span>
    <span class="model-action" @click="onEdit">修改</span>
    <a-modal
      title="修改链接"
      ok-text="确认"
      cancel-text="取消"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-input v-model="customer_url" placeholder="请输入单链接"/>
    </a-modal>  
  </span>
</template>

<script>
import { updateUrl } from "@/api/project"

export default {
  props: {
    single_url: {
      type: String,
      default: undefined
    },
    link_id: {
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      customer_url: undefined,
    };
  },
  created() {
  },
  methods: {
    onEdit() {
      this.customer_url = this.single_url;
      this.visible = true;
    },
    handleOk(e) {
      this.configSingleUrl();
    },
    handleCancel(e) {
      this.visible = false;
    },
    /// 更新链接
    async configSingleUrl() {
      this.confirmLoading = true;
      const that = this;
      try {
        await updateUrl({
          customer_url: this.customer_url,
          link_id: this.link_id - 0,
        });
        this.$message.success("更新成功");
        this.confirmLoading = false;
        this.visible = false;
        this.$emit('updatelist');
      } catch (error) {
        this.confirmLoading = false;
        console.log(error)
      }
    }
  }
};
</script>